<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-09-13 09:56:50
 * @LastEditTime: 2022-01-10 16:59:45
 * @Description: 物流场站地图
 * @Param:
 * @FilePath: \JT_Web_test\src\views\enterpriseScreen_JN\logisticsStationMap.vue
 -->

<template>
    <div
        class="logisticsStationMap"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <ul class="mapNav">
            <template v-for="(item, index) in mapNavData">
                <li
                    :key="`nav_${index}`"
                    :class="[`mapNav_${item.id}`, {'active': mapNavIndex === index}]"
                    @click="mapNavDataClick(item, index)"
                ></li>
            </template>
        </ul>
        <div
            :is="pagePath"
            :map-data="mapData"
            ref="ref_logisticsMap"
            :tenant-type="tenantType"
        ></div>
        <div class="btns" v-if="isShowBtn">
            <el-button type="primary" class="search iconfont iconsousuo1" v-popover:searchPopover></el-button>
            <el-button type="primary" class="setUp iconfont iconshezhi" v-popover:setUpPopover></el-button>
            <el-button type="primary" class="route iconfont iconhangxian" @click="logisticsClick"></el-button>
            <el-button type="primary" class="siteVehicles iconfont icongengduo1" v-popover:siteVehiclesPopover></el-button>
        </div>
        <!-- 搜索 -->
        <el-popover
            width="400"
            trigger="click"
            ref="searchPopover"
            v-model="searchVisible"
            placement="bottom-end"
            popper-class="mapPopover setUpPopover"
        >
            <h3>搜索：</h3>
            <el-input placeholder="工程名称/工程地址/车号/车牌号" v-model="searchVal"></el-input>
            <div class="setUpPopoverBtns">
                <el-button size="mini" type="text" @click="searchVisible = false">
                    取消
                </el-button>
                <el-button type="primary" size="mini" @click="getLocation">
                    确定
                </el-button>
            </div>
        </el-popover>
        <!-- 设置 -->
        <el-popover
            trigger="click"
            ref="setUpPopover"
            v-model="setUpVisible"
            placement="bottom-end"
            popper-class="mapPopover setUpPopover"
        >
            <h3>定位频率设置</h3>
            <el-input placeholder="请输入内容" v-model="setWholeTime">
                <template slot="append">
                    秒
                </template>
            </el-input>
            <div class="setUpPopoverBtns">
                <el-button size="mini" type="text" @click="setUpVisible = false">
                    取消
                </el-button>
                <el-button type="primary" size="mini" @click="setCarInterval(0)">
                    确定
                </el-button>
            </div>
        </el-popover>
        <el-popover
            trigger="click"
            ref="siteVehiclesPopover"
            placement="bottom-end"
            popper-class="mapPopover siteVehiclesPopover"
        >
            <div
                ref="ref_siteVehicles"
                :is="siteVehiclesView"
                :projects-address="projectsAddress"
                :car-statistics="carStatistics"
                :anomaly-statistics="anomalyStatistics"
                @mapDataLoading="mapDataLoading"
                @gitStationVehicles="gitStationVehicles"
            ></div>
        </el-popover>
    </div>
</template>

<script>
import { eventBus } from '@/common/eventBus.ts';
import largeScreenFun from './JS/index.js';
export default {
    name: 'logistics-station-map',
    components: {},
    props: {},
    data() {
        return {
            loading: false,
            time: '',
            stationId: '',

            mapNavData: [{
                name: '生产物流',
                id: 'scwl',
            },
            {
                name: '供应物流',
                id: 'gywl',
            },
            {
                name: '三维场站',
                id: 'swcz',
            }],
            mapNavIndex: 0,
            mapNavID: 'scwl',
            // 视图路径
            pagePath: '',

            isShowBtn: true,

            // 搜索
            searchVisible: false,
            searchVal: '',
            // 定位频率设置
            setUpVisible: false,
            setWholeTime: 0,

            siteVehiclesView: '',
            // 在施工地
            projectsAddress: [],
            // 运输车辆
            carStatistics: [],
            // 异常监控
            anomalyStatistics: [],

            // 地图数据
            mapData: {
                stationData: [],
                vehicleData: [],
            },

            // 三维场站数据
            tenantType: '',

            // 车辆状态变更-消息
            carStatus: {},
            // 车辆定位变更-消息
            carPosition: {},
        };
    },
    computed: {},
    watch: {
        carStatus: {
            deep: true,
            immediate: true,
            handler: function (val) {
                if (Object.keys(val).length > 0) {
                    debugger;
                }
            },
        },
        carPosition: {
            deep: true,
            immediate: true,
            handler: function (val) {
                if (Object.keys(val).length > 0) {
                    debugger;
                }
            },
        },
        mapNavID(newVal) {
            if (newVal === 'scwl') {
                this.loading = true;
                this.isShowBtn = true;
                this.pagePath = () => import('./components/productionMap/logisticsMap.vue');
                this.siteVehiclesView = () => import('./components/productionMap/siteVehicles.vue');
                this.initPage();
            } else if (newVal === 'gywl') {
                this.loading = true;
                this.isShowBtn = true;
                this.pagePath = () => import('./components/supplyMap/logisticsMap.vue');
                this.siteVehiclesView = () => import('./components/supplyMap/siteVehicles.vue');
                this.initPage();
            } else if (newVal === 'swcz') {
                this.isShowBtn = false;
                this.tenantType = 'jn';
                this.pagePath = () => import('@/views/EBCHome/threeDimensional/index.vue');
            }
        },
    },
    created() {
        this.pagePath = () => import('./components/productionMap/logisticsMap.vue');
        this.siteVehiclesView = () => import('./components/productionMap/siteVehicles.vue');
    },
    mounted() {
        // eventBus.$on('Monitoring', data => {
        //     this.carStatus = JSON.parse(data);
        // });
        // eventBus.$on('CoordinateMessage', data => {
        //     if (this.validData(data.tenant_id, data.mixstation_code)) {
        //         this.carPosition = JSON.parse(data);
        //     }
        // });
    },
    destroyed() {},
    methods: {
        async initPage() {
            this.time = this.$parent.time;
            this.stationId = this.$parent.stationId;
            this.gitStationVehicles();
            this.anomalyStatistics = await largeScreenFun.getExceptionsGroup();
        },

        async gitStationVehicles(type) {
            if (this.mapNavID === 'scwl') {
                await this.gitStationVehicles_scwl(type);
            } else if (this.mapNavID === 'gywl') {
                await this.gitStationVehicles_gywl(type);
            }
        },

        // 获取场站、车辆数据
        async gitStationVehicles_scwl(type) {
            if (['switch', 'search'].indexOf(type) > -1) {
                this.loading = true;
            }
            const newData = {};
            if (type !== 'search') {
                this.projectsAddress = await largeScreenFun.getConstructionSiteData(this.time, this.stationId);
                if (this.projectsAddress.length > 0) {
                    newData.stationData = this.projectsAddress;
                }
            }
            this.carStatistics = await largeScreenFun.getLocationData(this.stationId, this.searchVal);
            if (this.carStatistics.length > 0) {
                newData.vehicleData = this.carStatistics;
            }
            this.mapData = newData;
            this.loading = false;
        },

        // 获取货源地、车辆数据
        async gitStationVehicles_gywl() {
            const newData = {};
            const data = await largeScreenFun.getAllSupplyData(this.time, this.stationId, this.searchVal, this.tenantId);
            const sourceData = [];
            const sourceKey = [];
            const vehicleData = data;
            data.forEach(item => {
                if (!sourceKey.includes(item.name)) {
                    sourceKey.push(item.name);
                    sourceData.push(item);
                }
            });
            newData.stationData = sourceData;
            newData.vehicleData = vehicleData;

            this.projectsAddress = sourceData;
            this.carStatistics = vehicleData;
            this.mapData = newData;
            this.loading = false;
        },

        mapNavDataClick(row, index) {
            this.mapNavIndex = index;
            this.mapNavID = row.id;
        },

        // 设置车辆回写时间间隔
        setCarInterval(status) {
            const regPos = /^[0-9]+.?[0-9]*$/;
            const time = Number(this.setWholeTime);
            if (regPos.test(time)) {
                this.$axios
                    .put(`/interfaceApi/logistics/car_manager/set_car_interval/${status}/${time}`)
                    .then(() => {
                        this.$message.success('操作成功');
                        this.setUpVisible = false;
                        this.setWholeTime = 0;
                        this.setStatus = 0;
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message.error('请输入正确的时间');
            }
        },

        // 搜索
        getLocation() {
            this.searchVisible = false;
            this.gitStationVehicles('search');
        },

        // 生成物流线
        logisticsClick() {
            this.$refs.ref_logisticsMap.logisticsLoading();
        },

        // 在施工地、运输车辆选择回调
        mapDataLoading(row) {
            if (row.type === 'siteLoading') {
                this.mapData.stationData = row.data;
            } else if (row.type === 'vehicleLoading') {
                this.mapData.vehicleData = row.data;
            }
        },
    },
    beforeDestroy() {
        // 清除事件总线eventBus， 不手动清除，它是一直会存在的，就会反复进入
        eventBus.$off('Monitoring');
        eventBus.$off('CoordinateMessage');
    },
};
</script>
<style lang="stylus">
.mapPopover
    background: #132F55;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5), 0px 2px 6px 0px #000000;
    border-radius: 2px;
    border-color rgba(44, 72, 109, 1)
    .popper__arrow
        border-bottom-color rgba(44, 72, 109, 1) !important
        &:after
            border-bottom-color #132F55 !important
    .el-input__inner
        height 0.34rem
        line-height 0.34rem
        border 1px solid #223C61
        background-color #0F2647
    .el-input-group__append
        border 1px solid #223C61
        background-color #0F2647
.setUpPopover
    h3
        font-size 0.16rem
        margin-bottom 0.1rem
        color #fff
    .setUpPopoverBtns
        text-align right
        margin-top 0.1rem
.siteVehiclesPopover
    width 3rem !important
    padding: 0
    .siteVehicles
        .siteVehiclesNav
            width 100%
            display flex
            li
                flex 1
                height 0.32rem
                font-size 0.12rem
                line-height 0.32rem
                text-align center
                background #354D6C
                cursor pointer
                margin-right 1px
                &:last-child
                    margin-right 0
                &.active
                    background #1890FF
                color #fff
                span
                    font-size 0.14rem
        .vehicleData
            padding 0.1rem
            .classification
                display flex
                li
                    flex 1
                    height 0.56rem
                    margin-right 0.1rem
                    text-align center
                    background #284367
                    cursor pointer
                    h3
                        font-size 0.24rem
                        font-family technology
                    p
                        font-size 0.12rem
                        color #fff
                    &:last-child
                        margin-right 0
                    &:nth-of-type(1)
                        h3
                            color #1897FF
                    &:nth-of-type(2)
                        h3
                            color #F98C47
                    &:nth-of-type(3)
                        h3
                            color #0048B8
                    &:nth-of-type(4)
                        h3
                            color #FF6E77
                    &.active
                        background #132F55
                        box-shadow 0px 0px 16px inset #15BC64
                        border 1px solid #15BC64
                        h3
                            color #15BC64
            .el-carousel
                .el-carousel__indicators
                    bottom 0
                    .el-carousel__indicator
                        padding 0.1rem 0.04rem 0 0.04rem
        .vehicleList
            h4
                line-height 0.4rem
                background #1890FF
                font-size 0.18rem
                color #fff
                text-align center
            .input-with-select
                padding 0.1rem
                background #132F55
                .el-input__inner
                    height 0.3rem
                    line-height 0.3rem
                    border 1px solid #223C61
                    background-color #0F2647
                .el-input-group__append
                    border 1px solid #223C61
                    background-color #0F2647
            .vehicleTable
                max-height 1.8rem
                overflow-x hidden
                overflow-y auto
                table
                    width 100%
                    border-collapse collapse
                th,td
                    padding 0
                    height 0.4rem
                    text-align center
                    font-size 0.14rem
                thead
                    th
                        border none
                        background #2C486D
                        color #fff
                        font-weight 200
                tbody
                    tr
                        border 1px solid #435E86
                    td
                        background #132F55
                        color #fff
                        &:first-child
                            color #15BC64
                        &:last-child
                            color #18B6FF
            .abnormal-main
                height calc(100% - 1.38rem)
                width 100%
                .el-input__inner
                    background-color #314663
                    border 1px solid #314663
                    color #fff
                    &::-webkit-input-placeholder
                        font-size .14rem
                    &::-moz-placeholder
                        font-size .14rem
                    &::-ms-input-placeholder
                        font-size .14rem
                .abnormal-list
                    height calc(100% - .46rem)
                    overflow-y auto
                    ul
                        margin-bottom .1rem
                    li
                        display flex
                        justify-content space-between
                        padding .08rem
                        border 1px solid #50709F
                        font-size .14rem
                        color #fff
                        .plate-small-num
                            width .36rem
                            height .24rem
                            display inline-block
                            line-height .24rem
                            text-align center
                            background #FF7E00
                            font-size .14rem
                            color #fff
                            margin-right .1rem
                        .time
                            display flex
                            margin-top .03rem
                            .dot
                                display inline-block
                                width .06rem
                                height .06rem
                                background #FFFFFF
                                border-radius 50%
                                margin .05rem .1rem 0 0
                .el-pagination
                    .el-pagination__total
                        color #82A7DF
</style>

/*
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-09-07 14:00:58
 * @LastEditTime: 2021-09-26 16:00:12
 * @Description: 大屏请求
 * @Param:
 * @FilePath: \JT.Web\src\views\enterpriseScreen_JN\JS\index.js
 */
import axios from 'axios';
import util from '@/utils/util';
import { DigitRoll } from '@/assets/js/charts/digitRoll.js';

const largeScreenFun = Object.create(null);

/**
 * @Description             格式化电子时间
 * @param obj               容器对象
 * @param nub               数字
 */
largeScreenFun.electronicNumber = (obj, nub) => {
    if (!nub) {
        nub = 0.0;
    }
    new DigitRoll({
        container: obj,
    }).roll(nub);
};

largeScreenFun.MP = ak => {
    return new Promise(function (resolve, reject) {
        window.init = function () {
            // eslint-disable-next-line no-undef
            resolve(BMap);
        };
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://api.map.baidu.com/api?v=3.0&ak=${ak}&s=1&callback=init`;
        script.onerror = reject;
        document.head.appendChild(script);
    });
};

largeScreenFun.BMPGL = ak => {
    return new Promise(function (resolve, reject) {
        window.onload = function () {
            // eslint-disable-next-line
            resolve(BMapGL)
        };
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `http://api.map.baidu.com/api?v=1.0&type=webgl&ak=${ak}&callback=init`;
        script.onerror = reject;
        document.head.appendChild(script);
    });
};

/**
 * @Description             拉取场站
 */
largeScreenFun.getStationData = () => {
    const path = '/interfaceApi/report/largescreen3/czxx';
    return axios.get(path, { defEx: true });
};

/**
 * @Description             生产发货统计数据
 * @param date              时间
 * @param stationId         场站ID
 */
largeScreenFun.getShipmentStatistics = (date, stationId) => {
    const path = `/interfaceApi/report/largescreen/getproddomesticsum/date?date=${date}&station_id=${stationId}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             生产发货情况
 * @param date              时间
 * @param stationId         场站ID
 */
largeScreenFun.getDeliveryStatus = (date, stationId) => {
    const startTime = util.timeFormat(new Date(new Date(date).getTime() - 3600 * 1000 * 24 * 6), 'yyyy-MM-dd');
    const path = `/interfaceApi/report/largescreen/xsfhfldb/abh?station_id=${stationId}&start_time=${startTime}&end_time=${date}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             生产发货统计数据-按时间
 * @param date              时间
 * @param stationId         场站ID
 */
largeScreenFun.getShipmentStatistics_time = (date, stationId) => {
    const path = `/interfaceApi/report/largescreen/getproddomestictimesum/date?date=${date}&station_id=${stationId}`;
    return axios.get(path, { defEx: true });
};


/**
 * @Description             生产发货列表
 * @param date              时间
 * @param stationId         场站ID
 * @param searchVal         查询条件
 */
// eslint-disable-next-line max-params
largeScreenFun.getProductionDeliveryTable = (date, stationId, searchVal, pageindex, pagesize) => {
    const path = '/interfaceApi/report/largescreen/getproddomestic/date?'
    + `date=${date}&station_id=${stationId}&searchname=${searchVal}&pageindex=${pageindex}&pagesize=${pagesize}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             物资供应情况统计
 * @param date              时间
 * @param stationId         场站ID
 */
largeScreenFun.getSupplyStatistics = (date, stationId) => {
    const path = `/interfaceApi/report/largescreen/getprocdomesticsum/date?date=${date}&station_id=${stationId}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             物资供应情况统计-列表
 * @param date              时间
 * @param stationId         场站ID
 */
largeScreenFun.getSupplyStatistics_rawMaterial = (date, stationId) => {
    const path = `/interfaceApi/report/largescreen/getprocdomestic/date?date=${date}&station_id=${stationId}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             物资供应情况统计-图表
 * @param date              时间
 * @param typecode          原材料code
 */
largeScreenFun.getSupplyStatistics_chart = (date, typecode) => {
    const path = `/interfaceApi/report/largescreen/getprocdomesticbytypename/date?date=${date}&typecode=${typecode}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             供应物流车次
 * @param date              时间
 * @param stationId         场站ID
 * @param searchVal         查询条件
 * @param page              分页页码
 */
// eslint-disable-next-line max-params
largeScreenFun.getSupplyLogisticsStatistics = (date, tenantId, searchVal, page, pageSize) => {
    // eslint-disable-next-line max-len
    const path = `/interfaceApi/report/largescreen/getprocbalancedomestic/date?date=${date}&tenantId=${tenantId}&searchname=${searchVal}&pageindex=${page}&pagesize=${pageSize}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             料仓监控获取料仓信息
 * @param station_id        场站ID
 * @param tenant_id         租户id
 */
largeScreenFun.getSiloData = (station_id, tenant_id = '') => {
    const path = `/interfaceApi/report/largescreen/monitor/${station_id}?tenant_id=${tenant_id}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             视频监控-获取视频分组
 * @param station_id        场站ID
 * @param tenant_id         租户id
 */
largeScreenFun.generateNumber = (tenant_id, stationId) => {
    const path = `/interfaceApi/tenant/tenant3d/videogroup/${tenant_id}/${stationId}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             获取场站、车辆数据
 * @param date              时间
 * @param station_id        场站ID
 */
largeScreenFun.getConstructionSiteData = (date, station_id) => {
    const path = `/interfaceApi/production/producttask/get_project_status?date=${date}&stationCode=${station_id}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             获取当天有任务的工地
 * @param station_id        场站ID
 * @param searchVal         搜索条件
 */
largeScreenFun.getLocationData = (station_id, searchVal = '') => {
    const path = `/interfaceApi/production/vehicleinfo/get_location?stationId=${station_id}&carType=0&keywords=${searchVal}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             获取 运输车辆、货源地信息。  货源地 需要去重
 * @param station_id        场站ID
 * @param searchVal         搜索条件
 */
// eslint-disable-next-line max-params
largeScreenFun.getAllSupplyData = (date, station_id, searchVal = '', tenantId = '') => {
    // eslint-disable-next-line max-len
    const path = `/interfaceApi/report/largescreen/gettranlocationdomestic/date?date=${date}&station_id=${station_id}&searchname=${searchVal}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             获取场站位置
 * @param station_id        场站ID
 */
largeScreenFun.getStationLocation = station_id => {
    const path = `/interfaceApi/report/largescreen/getstationlat/station?station_id=${station_id}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             生产物流-获取生产发货运输位置信息
 * @param date              时间
 * @param stationCode       场站ID
 */
largeScreenFun.getSiteLocation = (date, station_id) => {
    const path = `/interfaceApi/production/producttask/get_project_status?date=${date}&stationCode=${station_id}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             供应物流-获取货源地地理位置信息
 * @param date              时间
 * @param station_id        场站ID
 */
// eslint-disable-next-line max-params
largeScreenFun.getSupplySiteLocation = (date, station_id, tenantId, searchVal = '') => {
    // eslint-disable-next-line max-len
    const path = `/interfaceApi/report/largescreen/getproddomesticlat/date?date=${date}&station_id=${station_id}&tenantId=${tenantId}&searchname=${searchVal}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             供应物流-获取供应地地理位置信息
 * @param date              时间
 * @param station_id        场站ID
 */
// eslint-disable-next-line max-params
largeScreenFun.getSupplyVehicleInfo = (date, carName, station_id, tenantId) => {
    const path = `/interfaceApi/report/largescreen/get_supply_car?date=${date}&plate_number=${carName}&station_id=${station_id}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             获取场站位置
 * @param station_id        场站ID
 */
largeScreenFun.getExceptionsGroup = () => {
    const path = '/interfaceApi/logistics/car_manager/get_exceptions_group';
    return axios.get(path, { defEx: true });
};

/**
 * @Description             获取场站位置
 * @param station_id        场站ID
 */
// eslint-disable-next-line max-params
largeScreenFun.getExceptions = (searchVal = '', pageindex, type, pagesize = 10) => {
    const path = `/interfaceApi/logistics/car_manager/get_exceptions?keywords=${searchVal}&pageindex=${pageindex}&pagesize=${pagesize}&type=${type}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             获取车辆信息
 * @param carName           车号
 */
largeScreenFun.getVehicleInfo = (date, carName) => {
    const path = `/interfaceApi/report/largescreen/getproddomesticcar/date?date=${date}&plate_number=${carName}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             获取工地信息
 * @param project_code      任务编号
 */
largeScreenFun.getConstructionSiteInfo = (date, project_code) => {
    const path = `/interfaceApi/report/largescreen/getproddomesticpub/date?date=${date}&project_code=${project_code}`;
    return axios.get(path, { defEx: true });
};


export default largeScreenFun;
